@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

@font-face {
  font-family: 'Ginora Sans';
  src: local('./assets/fonts/Ginóra-Sans-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Ginora Sans';
  src: url('./assets/fonts/Ginóra-Sans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ginora Sans';
  src: url('./assets/fonts/Ginóra-Sans-Semi-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

* {
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  min-height: 100%;
}

body {
  background: #FFF;
  -webkit-font-smoothing: antialiased !important;
}

.font-face-gs {
 font-family: "Ginora Sans";
}

