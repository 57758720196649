@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
}

#body-container {
  font-family: 'Ginora Sans';
  font-weight: normal;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* body {
    background-color: lightblue;
  } */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* body {
    background-color: lightcoral;
  } */

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /*body {
    background-color: lightgreen;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* body {
    background-color: lightslategrey;
  } */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* body {
    background-color: lightgoldenrodyellow;
  } */
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dark,
.dark * {
  background-color: #222;
  color: #e6e6e6;
  border-color: #e6e6e6;
}

.profile {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: yellow; */
  /* padding: 1em; */
}

.circle-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
}

#photo {
  width: 60%;
  border-radius: 100%;
  border: 2px solid #e6e6e6;
  padding: 8px;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.title span {
  font-size: 24pt;
}

.title h4 {
  font-size: 14pt;
}

#social-buttons {
  display: flex;
  align-items: center;
}

.social-buttons-vertical {
  flex-direction: column;
}

.social-buttons-horizontal {
  flex-direction: row;
}

.btn {
  background: #ffffff;
  border: solid 2px #e6e6e6;
  color: #000000;
  font-family: 'Ginora Sans';
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.btn-dark {
  background: #333333;
  border: none;
  color: #ffffff;
}

.rectangular-btn {
  border-radius: 18px;
  margin: 6px 2px;
  padding: 10px 4px;
  width: 130px;
}

.circular-btn {
  border-radius: 21pt;
  margin: 6px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32pt;
  height: 32pt;
  /* width: 130px; */
  /* padding: 4px 4px; */
}

.btn:hover {
  background: #f0f0f0;
}

.btn-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 0;
  margin: 0;
}

.btn-content i {
  font-size: 18px;
}

.name-and-badge {
  display: flex;
  align-items: center;
  justify-content: center;
}

#verified {
  width: 22px;
  margin-left: 8px;
}

.country {
  margin: 4px 0;
}
@font-face {
  font-family: 'Ginora Sans';
  src: local('./assets/fonts/Ginóra-Sans-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Ginora Sans';
  src: url(/static/media/Ginóra-Sans-Regular.dcdd6211.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ginora Sans';
  src: url(/static/media/Ginóra-Sans-Semi-Bold.b906ec3e.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

* {
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  min-height: 100%;
}

body {
  background: #FFF;
  -webkit-font-smoothing: antialiased !important;
}

.font-face-gs {
 font-family: "Ginora Sans";
}


